import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Button, Space, Size, IconActionLinkOpen, IconActionPlus } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed, Polymorphic } from '../common';
import { colorSystemSuccess, colorSystemWarning, colorSystemError } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <pre><code parentName="pre" {...{}}>{`import { Button } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma="https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/?node-id=274%3A583" storybook="/?path=/story/inputs-button--playground" vue="/components/HseButton/HseButton.html" vueStorybook="/?path=/story/inputs-button--playground" mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Кнопка — это основной интерактивный элемент, предназначенный для выполнения действий на странице.`}</p>
    <p>{`Есть четыре варианта кнопки: `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`, `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{` и `}<inlineCode parentName="p">{`tertiaryInvert`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`primary`}</inlineCode>{` используется, когда действие на странице первично, `}<inlineCode parentName="p">{`secondary`}</inlineCode>{` — для
дополнительного, второстепенного по смыслу действия, `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{` - для особых случаев,
`}<inlineCode parentName="p">{`tertiaryInvert`}</inlineCode>{` - для особых случаев на темном или цветном фоне.`}</p>
    <p>{`Варианты `}<inlineCode parentName="p">{`primary`}</inlineCode>{` и `}<inlineCode parentName="p">{`secondary`}</inlineCode>{` подходят для действий в рамках текущей страницы, таких как отправка формы.
Варианты `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{` и `}<inlineCode parentName="p">{`tertiaryInvert`}</inlineCode>{` можно также использовать для навигационных элементов.`}</p>
    <Polymorphic component="Button" mdxType="Polymorphic" />
    <p>{`При использовании кнопки в качестве навигационного элемента рекомендуется использовать базовый тег `}<inlineCode parentName="p">{`a`}</inlineCode>{` или `}<inlineCode parentName="p">{`Link`}</inlineCode>{` из `}<inlineCode parentName="p">{`react-router`}</inlineCode>{` (см. пример `}<a parentName="p" {...{
        "href": "#%D0%B7%D0%B0%D0%BC%D0%B5%D0%BD%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%B0"
      }}>{`ниже`}</a>{`).`}</p>
    <Playground __position={2} __code={'<Button variant=\"primary\" size=\"medium\">\n  Primary\n</Button>\n<Space size=\"small_2x\" vertical />\n<Button variant=\"secondary\" size=\"medium\">\n  Secondary\n</Button>\n<Space size=\"small_2x\" vertical />\n<Button variant=\"tertiary\" size=\"medium\">\n  Tertiary\n</Button>\n<Space size=\"small_2x\" vertical />\n<div style={{ background: \'#6B7A99\', padding: \'12px\' }}>\n  <Button variant=\"tertiaryInvert\" size=\"medium\">\n    Tertiary invert\n  </Button>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button variant="primary" size="medium" mdxType="Button">
    Primary
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <Button variant="secondary" size="medium" mdxType="Button">
    Secondary
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <Button variant="tertiary" size="medium" mdxType="Button">
    Tertiary
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <div style={{
        background: '#6B7A99',
        padding: '12px'
      }}>
    <Button variant="tertiaryInvert" size="medium" mdxType="Button">
      Tertiary invert
    </Button>
  </div>
    </Playground>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={3} __code={'<button className=\"hse-Button hse-Button_size_medium hse-Button_variant_primary hse-Button_hasSpinner hse-Button_hasLeft hse-Button_hasSideContent hse-Button_spinner_true\">\n  <div className=\"hse-Button__bubbleRoot\"></div>\n  <div className=\"hse-Button__content\">\n    <div className=\"hse-Button__contentLeft\">\n      <div\n        style={{\n          height: \'24px\',\n          width: \'24px\',\n          minWidth: \'24px\',\n          padding: 0,\n        }}\n        className=\"hse-Spinner hse-Spinner_loading hse-Button__spinner hse-Spinner_without-done-fill-animation hse-Button__spinner_left\"\n      >\n        <svg\n          xmlns=\"http://www.w3.org/2000/svg\"\n          viewBox=\"21.81818181818182 21.81818181818182 43.63636363636364 43.63636363636364\"\n          className=\"hse-Spinner__checkmark\"\n        >\n          <circle\n            className=\"hse-Spinner__overlay\"\n            fill=\"transparent\"\n            cx=\"43.63636363636364\"\n            cy=\"43.63636363636364\"\n            r=\"20\"\n            strokeWidth=\"3.6363636363636367\"\n            strokeDasharray=\"125.664\"\n            strokeDashoffset=\"62.83185307179586\"\n          />\n        </svg>\n      </div>\n    </div>\n    <div className=\"hse-Button__text\">Кнопка</div>\n  </div>\n</button>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <button className='hse-Button hse-Button_size_medium hse-Button_variant_primary hse-Button_hasSpinner hse-Button_hasLeft hse-Button_hasSideContent hse-Button_spinner_true'>
      <div className='hse-Button__bubbleRoot'></div>
      <div className='hse-Button__content'>
        <div className='hse-Button__contentLeft'>
	        <div style={{
              height: '24px',
              width: '24px',
              minWidth: '24px',
              padding: 0
            }} className='hse-Spinner hse-Spinner_loading hse-Button__spinner hse-Spinner_without-done-fill-animation hse-Button__spinner_left'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox='21.81818181818182 21.81818181818182 43.63636363636364 43.63636363636364' className='hse-Spinner__checkmark'>
              <circle className="hse-Spinner__overlay" fill="transparent" cx="43.63636363636364" cy="43.63636363636364" r="20" strokeWidth="3.6363636363636367" strokeDasharray="125.664" strokeDashoffset="62.83185307179586" />
            </svg>
	        </div>
        </div>
        <div className='hse-Button__text'>
          Кнопка
        </div>
      </div>
    </button>
    </Playground>
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9262:32727" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер-кнопки"
    }}>{`Размер кнопки`}</h3>
    <p>{`Кнопка может быть маленькой, средней и большой.`}</p>
    <Playground __position={5} __code={'<Button variant=\"primary\" size=\"small\">\n  Маленькая\n</Button>\n<Space size=\"small_2x\" vertical />\n<Button variant=\"primary\" size=\"medium\">\n  Средняя\n</Button>\n<Space size=\"small_2x\" vertical />\n<Button variant=\"primary\" size=\"large\">\n  Большая\n</Button>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button variant="primary" size="small" mdxType="Button">
    Маленькая
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <Button variant="primary" size="medium" mdxType="Button">
    Средняя
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <Button variant="primary" size="large" mdxType="Button">
    Большая
  </Button>
    </Playground>
    <h3 {...{
      "id": "disabled-и-inactive"
    }}>{`Disabled и inactive`}</h3>
    <p>{`Кнопка может находиться в состояниях `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` и `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`disabled`}</inlineCode>{` используется, когда пользователь не может перейти к следующему шагу, пока не
совершит определенное действие, а `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` — для кнопки, нажатие на которую
в принципе не может быть выполнено.`}</p>
    <Playground __position={6} __code={'<Button variant=\"primary\" size=\"medium\" disabled={true}>\n  Disabled\n</Button>\n<Space size=\"small_2x\" vertical />\n<Button variant=\"primary\" size=\"medium\" inactive={true}>\n  Inactive\n</Button>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button variant="primary" size="medium" disabled={true} mdxType="Button">
    Disabled
  </Button>
  <Space size="small_2x" vertical mdxType="Space" />
  <Button variant="primary" size="medium" inactive={true} mdxType="Button">
    Inactive
  </Button>
    </Playground>
    <h3 {...{
      "id": "выделяемая-кнопка"
    }}>{`Выделяемая кнопка`}</h3>
    <p>{`Button в варианте `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{` может быть выделяемой.
Для выделения кнопки передайте ей проп `}<inlineCode parentName="p">{`selected`}</inlineCode>{`.`}</p>
    <p>{`Выделяемую кнопку рекомендуется использовать только в тех случаях, когда есть группа кнопок,
и может быть выделена только одна из них (например, пагинация или календарь).
В качестве одинокого переключателя лучше подойдет Checkbox или Switcher.
Чтобы избежать визуальных конфликтов, не следует размещать выделяемую кнопку рядом с `}<inlineCode parentName="p">{`primary`}</inlineCode>{`-кнопками, в том числе IconButton.`}</p>
    <Playground __position={7} __code={'() => {\n  const [selected, setSelected] = useState(false)\n  return (\n    <Button\n      selected={selected}\n      onClick={() => setSelected(s => !s)}\n      variant={Button.Variant.tertiary}\n      size={Button.Size.medium}\n    >\n      Tertiary\n    </Button>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [selected, setSelected] = useState(false);
        return <Button selected={selected} onClick={() => setSelected(s => !s)} variant={Button.Variant.tertiary} size={Button.Size.medium} mdxType="Button">
        Tertiary
      </Button>;
      }}
    </Playground>
    <h3 {...{
      "id": "цвет-кнопки"
    }}>{`Цвет кнопки`}</h3>
    <p>{`Вы можете задать любой цвет фона кнопки с помощью свойства `}<inlineCode parentName="p">{`color`}</inlineCode>{`. Рекомендуется использовать цвета из токенов.`}</p>
    <p>{`Цвет фона различных состояний меняется в соответствии с системой, описанной в `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=4497%3A12188"
      }}>{`Figma`}</a></p>
    <Playground __position={8} __code={'() => {\n  const variants = {\n    primary: \'\',\n    success: colorSystemSuccess,\n    warning: colorSystemWarning,\n    error: colorSystemError,\n  }\n  return (\n    <div style={{ display: \'flex\' }}>\n      {Object.keys(variants).map(v => (\n        <div style={{ display: \'flex\' }} key={v}>\n          <Button color={variants[v]}>{v}</Button>\n          <Space size={Size.small_2x} horizontal />\n        </div>\n      ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const variants = {
          primary: '',
          success: colorSystemSuccess,
          warning: colorSystemWarning,
          error: colorSystemError
        };
        return <div style={{
          display: 'flex'
        }}>
      {Object.keys(variants).map(v => <div style={{
            display: 'flex'
          }} key={v}>
          <Button color={variants[v]} mdxType="Button">
            {v}
          </Button>
          <Space size={Size.small_2x} horizontal mdxType="Space" />
        </div>)}
    </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "состояние-загрузки"
    }}>{`Состояние загрузки`}</h3>
    <p>{`В кнопку можно добавить Spinner с помощью свойства `}<inlineCode parentName="p">{`spinner`}</inlineCode>{`.
Можно передать значения `}<inlineCode parentName="p">{`true`}</inlineCode>{`, `}<inlineCode parentName="p">{`'done'`}</inlineCode>{` или число, задающее заполнение спиннера в процентах.`}</p>
    <p>{`С помощью свойства `}<inlineCode parentName="p">{`spinnerPosition`}</inlineCode>{` Spinner можно расположить справа и слева от текста (значения свойства `}<inlineCode parentName="p">{`left`}</inlineCode>{` и `}<inlineCode parentName="p">{`right`}</inlineCode>{`),
а также полностью заменить текст на Spinner (значение свойства `}<inlineCode parentName="p">{`default`}</inlineCode>{`).`}</p>
    <Playground __position={9} __code={'<div>\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <Button spinner={50}>Loading..</Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner={true}>Loading..</Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner=\"done\">Done!</Button>\n  </div>\n  <Space size=\"small_2x\" vertical />\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <Button spinner={50} spinnerPosition={Button.SpinnerPosition.left}>\n      Loading..\n    </Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner={true} spinnerPosition={Button.SpinnerPosition.left}>\n      Loading..\n    </Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner=\"done\" spinnerPosition={Button.SpinnerPosition.left}>\n      Loading..\n    </Button>\n  </div>\n  <Space size=\"small_2x\" vertical />\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <Button spinner={50} spinnerPosition={Button.SpinnerPosition.right}>\n      Loading..\n    </Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner={true} spinnerPosition={Button.SpinnerPosition.right}>\n      Loading..\n    </Button>\n    <Space size=\"small_2x\" horizontal />\n    <Button spinner=\"done\" spinnerPosition={Button.SpinnerPosition.right}>\n      Loading..\n    </Button>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
            <Button spinner={50} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner={true} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner="done" mdxType="Button">
                Done!
            </Button>
        </div>
        <Space size="small_2x" vertical mdxType="Space" />
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
            <Button spinner={50} spinnerPosition={Button.SpinnerPosition.left} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner={true} spinnerPosition={Button.SpinnerPosition.left} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner="done" spinnerPosition={Button.SpinnerPosition.left} mdxType="Button">
                Loading..
            </Button>
        </div>
        <Space size="small_2x" vertical mdxType="Space" />
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
            <Button spinner={50} spinnerPosition={Button.SpinnerPosition.right} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner={true} spinnerPosition={Button.SpinnerPosition.right} mdxType="Button">
                Loading..
            </Button>
            <Space size="small_2x" horizontal mdxType="Space" />
            <Button spinner="done" spinnerPosition={Button.SpinnerPosition.right} mdxType="Button">
                Loading..
            </Button>
        </div>
    </div>
    </Playground>
    <h3 {...{
      "id": "иконки-справа-и-слева"
    }}>{`Иконки справа и слева`}</h3>
    <p>{`Компоненту можно добавить иконки слева и справа от текста свойствами `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` и `}<inlineCode parentName="p">{`rightIcon`}</inlineCode></p>
    <Playground __position={10} __code={'<div style={{ display: \'flex\' }}>\n  <Button leftIcon={IconActionPlus}>Add</Button>\n  <Space size=\"small_2x\" horizontal />\n  <Button rightIcon={IconActionPlus}>Add</Button>\n  <Space size=\"small_2x\" horizontal />\n  <Button leftIcon={IconActionPlus} rightIcon={IconActionPlus}>\n    Add\n  </Button>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        display: 'flex'
      }}>
        <Button leftIcon={IconActionPlus} mdxType="Button">
            Add
        </Button>
        <Space size="small_2x" horizontal mdxType="Space" />
        <Button rightIcon={IconActionPlus} mdxType="Button">
            Add
        </Button>
        <Space size="small_2x" horizontal mdxType="Space" />
        <Button leftIcon={IconActionPlus} rightIcon={IconActionPlus} mdxType="Button">
            Add
        </Button>
    </div>
    </Playground>
    <h3 {...{
      "id": "слоты-слева-и-справа"
    }}>{`Слоты слева и справа`}</h3>
    <p>{`Компоненту можно добавить произвольный контент слева и справа с помощью пропов `}<inlineCode parentName="p">{`left`}</inlineCode>{` и `}<inlineCode parentName="p">{`right`}</inlineCode></p>
    <Playground __position={11} __code={'<div style={{ display: \'flex\' }}>\n  <Button left={<span style={{ margin: \'0 5px\' }}>Left</span>}>Add</Button>\n  <Space size=\"small_2x\" horizontal />\n  <Button right={<span style={{ margin: \'0 5px\' }}>Right</span>}>Add</Button>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        display: 'flex'
      }}>
        <Button left={<span style={{
          margin: '0 5px'
        }}>Left</span>} mdxType="Button">
            Add
        </Button>
        <Space size="small_2x" horizontal mdxType="Space" />
        <Button right={<span style={{
          margin: '0 5px'
        }}>Right</span>} mdxType="Button">
            Add
        </Button>
    </div>
    </Playground>
    <h3 {...{
      "id": "замена-компонента"
    }}>{`Замена компонента`}</h3>
    <p>{`По-умолчанию в качестве базового компонента кнопки используется `}<inlineCode parentName="p">{`button`}</inlineCode>{`.
Но можно также использовать любой другой, например `}<inlineCode parentName="p">{`a`}</inlineCode>{` или `}<inlineCode parentName="p">{`Link`}</inlineCode>{` (из react-router).
Для этого передайте свойство `}<inlineCode parentName="p">{`component`}</inlineCode>{`.
Все неиспользуемые пропы `}<inlineCode parentName="p">{`Button`}</inlineCode>{` будут переданы базовому компоненту.`}</p>
    <Playground __position={12} __code={'<Button\n  component={\'a\'}\n  href=\"https://hse.ru\"\n  target=\"_blank\"\n  variant={Button.Variant.tertiary}\n  rightIcon={IconActionLinkOpen}\n>\n  Кнопка-ссылка\n</Button>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Button component={'a'} href="https://hse.ru" target="_blank" variant={Button.Variant.tertiary} rightIcon={IconActionLinkOpen} mdxType="Button">
        Кнопка-ссылка
    </Button>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Button} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент в соответствии со значением `}<inlineCode parentName="p">{`component`}</inlineCode>{` (по умолчанию - `}<inlineCode parentName="p">{`button`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока: `}<inlineCode parentName="p">{`hse-Button`}</inlineCode></p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_size_small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Задает маленький размер кнопки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_size_medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Задает средний размер кнопки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_size_large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Задает большой размер кнопки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_variant_primary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку первичной (с вариантом primary)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_variant_secondary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку вторичной (с вариантом secondary)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_variant_tertiary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку третичной (с вариантом tertiary)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_variant_tertiaryInvert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку третичной c инверсией (с вариантом tertiaryInvert)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку неработоспособной`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_inactive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку неактивной`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает кнопку выбранной`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_custom-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Задает currentColor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_hasSpinner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Наличие спиннера`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_hasLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Слева от текста есть слот`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_hasRight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Справа от текста есть слот`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button_hasSideContent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Или слева, или справа от текста есть слот`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "элементы"
    }}>{`Элементы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__bubbleRoot`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Бабл`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Контент кнопки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__contentLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Контент кнопки слева от текста`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__contentRight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Контент кнопки справа от текста`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Иконка слева или справа от текста`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__spinner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Спиннер слева или справа от текста`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Текст кнопки`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "модификаторы-элементов"
    }}>{`Модификаторы элементов`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__icon_left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Иконка слева`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__icon_right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Иконка справа`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__spinner_left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Спиннер слева`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Button__spinner_right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Спиннер справа`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования-1"
    }}>{`Пример использования`}</h3>
    <Playground __position={14} __code={'<button className=\"hse-Button hse-Button_size_medium hse-Button_variant_primary hse-Button_hasLeft hse-Button_hasSideContent\">\n  <div className=\"hse-Button__bubbleRoot\"></div>\n  <div className=\"hse-Button__content\">\n    <div className=\"hse-Button__contentLeft\">\n      <svg\n        width=\"1em\"\n        height=\"1em\"\n        viewBox=\"0 0 24 24\"\n        fill=\"none\"\n        xmlns=\"http://www.w3.org/2000/svg\"\n        className=\"hse-Icon hse-Icon_size_medium hse-Button__icon hse-Button__icon_left\"\n      >\n        <path\n          fillRule=\"evenodd\"\n          clipRule=\"evenodd\"\n          d=\"M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z\"\n          fill=\"currentColor\"\n        />\n      </svg>\n    </div>\n    <div className=\"hse-Button__text\">Кнопка</div>\n  </div>\n</button>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      Space,
      Size,
      IconActionLinkOpen,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      Polymorphic,
      colorSystemSuccess,
      colorSystemWarning,
      colorSystemError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <button className='hse-Button hse-Button_size_medium hse-Button_variant_primary hse-Button_hasLeft hse-Button_hasSideContent'>
      <div className='hse-Button__bubbleRoot'></div>
      <div className='hse-Button__content'>
        <div className='hse-Button__contentLeft'>
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='hse-Icon hse-Icon_size_medium hse-Button__icon hse-Button__icon_left'>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z" fill="currentColor" />
          </svg>
        </div>
        <div className='hse-Button__text'>
          Кнопка
        </div>
      </div>
    </button>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      